import React, { ReactNode } from "react";
import FooterContent from "../Footer";
import HeaderContent from "../Header";

import { StyledContent } from "../common/styles";

type Props = {
  children: ReactNode;
};
const AppContent: React.FC<Props> = ({ children }) => {
  return (
    <>
      <HeaderContent />
      <StyledContent>{children}</StyledContent>
      <FooterContent />
    </>
  );
};

export default AppContent;
