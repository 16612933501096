import Link from "next/link";
import * as Props from "../../types/PropsTypes";
import styles from "./Common.module.scss";
import {
  BuyMeACoffeeIcon,
  DiscordIcon,
  FacebookIcon,
  OmiPlayIcon,
  TelegramIcon,
} from "../../svg";
import { Routes } from "../../config/routes";
import Image from "next/image";

export const StyledLink: React.FC<Props.Link> = ({
  children,
  href,
  target,
  rel,
  className,
  ariaLabel,
}) => (
  <Link
    href={href}
    target={target}
    rel={rel ? "noreferrer noopener" : ""}
    className={className ? className : styles.link}
    aria-label={ariaLabel}
  >
    {children}
  </Link>
);

export const StyledImg: React.FC<Props.Img> = ({
  src,
  alt,
  width,
  height,
  className,
}) => (
  <Image
    src={src}
    alt={alt}
    width={width}
    height={height}
    className={className ? className : styles.displayBlock}
  />
);

export const Button: React.FC<Props.Button> = ({
  children,
  onClick,
  className,
  type = "button",
  ariaExpanded,
  ariaLabel,
}) => (
  <button
    onClick={onClick}
    type={type}
    className={className ? className : ""}
    aria-expanded={ariaExpanded}
    aria-label={ariaLabel}
  >
    {children}
  </button>
);

export const Logo: React.FC<Props.Icon> = ({ className }) => (
  <StyledLink ariaLabel="Go to home" href={Routes.Root}>
    <OmiPlayIcon className={className ? className : styles.logo} />
  </StyledLink>
);

export const SudokuProLink: React.FC<Props.GameLink> = ({ section }) => (
  <StyledLink
    className={section === "footer" ? styles.gameLinkfooter : styles.gameLink}
    ariaLabel="Play Sudoku Online Free"
    href="https://sudokupro.app/"
    rel
    target="_blank"
  >
    Play Sudoku
  </StyledLink>
);

export const MahjongLink: React.FC<Props.GameLink> = ({ section }) => (
  <StyledLink
    className={section === "footer" ? styles.gameLinkfooter : styles.gameLink}
    ariaLabel="Play Mahjong Online Free"
    href="https://mahjong-online.club/"
    rel
    target="_blank"
  >
    Play Mahjong
  </StyledLink>
);

export const Socials: React.FC<Props.Socials> = ({ section }) => (
  <div
    className={
      section === "footer" ? styles.socialsFooter : styles.socialsHeader
    }
  >
    <StyledLink
      className={styles.socialLink}
      href="https://t.me/omiplaycom"
      rel
      target="_blank"
      ariaLabel="Go to OmiPlay Telegram Channel"
    >
      <TelegramIcon className={styles.socialImg} />
    </StyledLink>

    <StyledLink
      className={styles.socialLink}
      href="https://discord.gg/mWvhWqAq"
      rel
      target="_blank"
      ariaLabel="Go to OmiPlay Discord Channel"
    >
      <DiscordIcon className={styles.socialImg} />
    </StyledLink>

    <StyledLink
      className={styles.socialLink}
      href="https://www.facebook.com/omiplaycom"
      rel
      target="_blank"
      ariaLabel="Go to OmiPlay Facebook Page"
    >
      <FacebookIcon className={styles.socialImg} />
    </StyledLink>
  </div>
);

export const ColoredText: React.FC<Props.Base> = ({ children }) => (
  <span className={styles.coloredText}>{children}</span>
);

export const BuyUsACoffee: React.FC<Props.GameLink> = ({ section }) => (
  <StyledLink
    className={section === "footer" ? styles.gameLinkfooter : styles.gameLink}
    ariaLabel="Buy Me A Coffee OmiPlay Link"
    href="https://buymeacoffee.com/omiplay"
    rel
    target="_blank"
  >
    <div className={styles.buyMeACoffeeButton}>
      <BuyMeACoffeeIcon className={styles.buyMeACoffeeIcon} />

      <p>Buy us a Coffee</p>
    </div>
  </StyledLink>
);
