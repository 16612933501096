import React from "react";
import COLORS from "../constants/colors";

const ScrollDownIcon: React.FC = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='8'
      height='19'
      viewBox='0 0 8 19'
      fill='none'
    >
      <path
        d='M3.64645 18.3536C3.84171 18.5488 4.15829 18.5488 4.35355 18.3536L7.53553 15.1716C7.7308 14.9763 7.7308 14.6597 7.53553 14.4645C7.34027 14.2692 7.02369 14.2692 6.82843 14.4645L4 17.2929L1.17157 14.4645C0.97631 14.2692 0.659728 14.2692 0.464465 14.4645C0.269203 14.6597 0.269203 14.9763 0.464465 15.1716L3.64645 18.3536ZM3.5 -2.18557e-08L3.5 18L4.5 18L4.5 2.18557e-08L3.5 -2.18557e-08Z'
        fill={COLORS.MAIN}
      />
    </svg>
  );
};

export default ScrollDownIcon;
