import { Button, styled } from "@mui/material";
import { tablet, desktop } from "../../constants/breakpoints";
import COLORS from "../../constants/colors";

export const ColoredText = styled("span")(({ theme, className }) => ({
  color: COLORS.MAIN,
}));

export const StyledTitle = styled("h1")(() => ({
  fontWeight: 700,
  fontSize: 22,
  color: COLORS.MAIN,
  margin: "30px 0",

  ...tablet({
    fontSize: 32,
    margin: "80px 0 30px",
  }),
  ...desktop({ marginTop: 44 }),
}));

export const SudokuContainer = styled("div")(() => ({
  background: COLORS.SECONDARY,
  borderRadius: 15,
  position: "relative",
  top: -80,
  ...desktop({
    top: 0,
  }),
}));

export const CustomImg = styled("img")(({ theme, width, height }) => ({
  width: width,
  height: height,
}));

export const Socials = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
  gap: theme.spacing(4),
  ...tablet({
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  }),
}));

export const StyledLink = styled("a")(() => ({
  color: COLORS.BLACK,
  fontWeight: 500,
  fontSize: 16,
  textDecoration: "none",
  cursor: "pointer",
  textAlign: "center",
  "&:hover": {
    color: COLORS.MAIN,
  },
}));

export const Content = styled("div")(() => ({
  minHeight: "100%",

  ...desktop({
    alignItems: "flex-start",
    position: "relative",
    display: "flex",
    justifyContent: "space-between",
  }),
}));

export const SudokuPro = styled("div")(() => ({
  ...tablet({
    marginLeft: 20,
  }),
}));

export const StyledContent = styled("div")(() => ({
  flexGrow: 1,
}));

export const MainWrapper = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh",
  overflowX: "hidden",
}));

export const PageNotFoundWrapper = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  marginTop: 40,
  ...tablet({
    marginTop: 59,
  }),
  ...desktop({
    height: "75vh",
    justifyContent: "space-between",
    flexDirection: "row",
  }),
}));

export const PageNotFoundTitle = styled("div")(({ className }) => ({
  fontWeight: 700,
  fontSize: 28,
  color: COLORS.BLACK,
  textTransform: "uppercase",
  lineHeight: 1.2,
  marginLeft: className?.includes("subtitle") ? 60 : 0,
  ...tablet({
    fontSize: 60,
    lineHeight: 1.3,
  }),
  ...desktop({
    marginLeft: className?.includes("subtitle") ? 110 : 0,
  }),
}));

export const PageNotFoundInfo = styled("div")(() => ({
  margin: "40px 0",
  ...desktop({
    margin: 0,
    alignSelf: "center",
  }),
}));
export const PageNotFoundText = styled("div")(() => ({
  margin: "20px 0 20px 60px",
  fontSize: 14,
  lineHeight: 1.2,
  ...tablet({
    width: "40%",
  }),
  ...desktop({
    margin: "25px 0 25px 110px",
    width: "70%",
    fontSize: 16,
    lineHeight: 1.3,
  }),
}));

export const PageNotFoundButton = styled(Button)(({ theme, className }) => ({
  border: `2px solid ${COLORS.MAIN}`,
  borderRadius: 0,
  color: COLORS.MAIN,
  padding: "14px 95px",
  lineHeight: 1.2,
  fontSize: 18,
  cursor: "pointer",
  fontWeight: 700,
  ...desktop({
    padding: "17px 115px",
  }),
}));

export const PageNotFoundImage = styled("div")(() => ({
  width: "100%",
  backgroundImage: `url(/images/404.webp)`,
  height: " 90vw",
  zIndex: -1,
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
  marginBottom: 40,

  ...tablet({
    height: 600,
    marginBottom: 0,
  }),
  ...desktop({
    width: "50%",
    backgroundPosition: "center",
  }),
}));
