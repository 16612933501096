import * as Props from "../types/PropsTypes";

const BurgerMenuIcon: React.FC<Props.Icon> = ({ className }) => (
  <svg
    className={className}
    viewBox="0 0 33 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 1C0 0.447715 0.671573 0 1.5 0H31.5C32.3284 0 33 0.447715 33 1C33 1.55228 32.3284 2 31.5 2H1.5C0.671573 2 0 1.55228 0 1Z"
      fill="#5E7EF0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M-8.74227e-08 8C-3.91405e-08 7.44771 1.00736 7 2.25 7L30.75 7C31.9926 7 33 7.44772 33 8C33 8.55229 31.9926 9 30.75 9L2.25 9C1.00736 9 -1.35705e-07 8.55228 -8.74227e-08 8Z"
      fill="#5E7EF0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 15C0 14.4477 0.671573 14 1.5 14H31.5C32.3284 14 33 14.4477 33 15C33 15.5523 32.3284 16 31.5 16H1.5C0.671573 16 0 15.5523 0 15Z"
      fill="#5E7EF0"
    />
  </svg>
);

export default BurgerMenuIcon;
