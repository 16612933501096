import * as Props from "../../../types/PropsTypes";
import styles from "./MobileMenu.module.scss";

export const MobileMenuWrapper: React.FC<Props.MobileMenu> = ({
  children,
  isMenuOpen,
}) => (
  <div className={isMenuOpen ? styles.mobileMenuOpened : styles.mobileMenu}>
    {children}
  </div>
);
