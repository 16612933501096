import * as Props from "../types/PropsTypes";

const CrossIcon: React.FC<Props.Icon> = ({ className }) => (
  <svg
    className={className}
    viewBox="0 0 27 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.922268 21.978C0.567266 21.5549 0.793935 20.7803 1.42855 20.2478L24.4099 0.964165C25.0445 0.431662 25.8467 0.342953 26.2017 0.766028C26.5567 1.1891 26.3301 1.96375 25.6955 2.49625L2.71412 21.7799C2.07951 22.3124 1.27727 22.4011 0.922268 21.978Z"
      fill="#5E7EF0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.9223 22.19C25.5673 22.6131 24.765 22.5244 24.1304 21.9919L1.14908 2.70824C0.514469 2.17574 0.287799 1.40109 0.642801 0.978018C0.997803 0.554944 1.80004 0.643653 2.43466 1.17616L25.416 20.4598C26.0506 20.9923 26.2773 21.7669 25.9223 22.19Z"
      fill="#5E7EF0"
    />
  </svg>
);

export default CrossIcon;
