import React from "react";
import Container from "../Container";
import {
  Logo,
  SudokuProLink,
  BuyUsACoffee,
  Socials,
  MahjongLink,
} from "../common/Common.components";
import {
  Copyright,
  Footer,
  PrivacyPolicyLink,
  Wrapper,
  WrapperLinks,
} from "./Footer.components";

const FooterContent: React.FC = () => {
  return (
    <Footer>
      <Container>
        <Wrapper>
          <Logo />
          <WrapperLinks>
            <SudokuProLink section="footer" />
            <MahjongLink section="footer" />
          </WrapperLinks>
          <Socials section="footer" />
        </Wrapper>

        <Wrapper>
          <BuyUsACoffee section="footer" />

          <Copyright />

          <PrivacyPolicyLink />
        </Wrapper>
      </Container>
    </Footer>
  );
};

export default FooterContent;
