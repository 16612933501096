import React from "react";
import COLORS from "../constants/colors";

const CloseTagIcon: React.FC = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
    >
      <path
        d='M15 5.875L14.125 5L10 9.125L5.875 5L5 5.875L9.125 10L5 14.125L5.875 15L10 10.875L14.125 15L15 14.125L10.875 10L15 5.875Z'
        fill={COLORS.WHITE}
      />
    </svg>
  );
};

export default CloseTagIcon;
