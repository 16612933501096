import React from "react";
import COLORS from "../constants/colors";

const EmailInputArrow: React.FC = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='22'
      height='22'
      viewBox='0 0 22 22'
      fill='none'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.12132 21.567L0 19.4457L19.0607 0.385026L21.182 2.50635L2.12132 21.567Z'
        fill={COLORS.MAIN}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.5607 3H4.56067V0H21.5607V16.5H18.5607V3Z'
        fill={COLORS.MAIN}
      />
    </svg>
  );
};

export default EmailInputArrow;
