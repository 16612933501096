import ClockIcon from "./ClockIcon";
import OmiPlayIcon from "./OmiPlayIcon";
import EmailInputArrow from "./EmailInputArrow";
import ScrollDownIcon from "./ScrollDownIcon";
import SearchIcon from "./SearchIcon";
import CloseTagIcon from "./CloseTagIcon";
import CrossIcon from "./CrossIcon";
import BurgerMenuIcon from "./BurgerMenuIcon";
import InstagramIcon from "./InstagramIcon";
import FacebookIcon from "./FacebookIcon";
import TwitterIcon from "./TwitterIcon";
import DiscordIcon from "./DiscordIcon";
import BuyMeACoffeeIcon from "./BuyMeACoffeeIcon";
import BuyMeACoffeeRoundIcon from "./BuyMeACoffeeRoundIcon";
import TelegramIcon from "./TelegramIcon";

export {
  ClockIcon,
  OmiPlayIcon,
  EmailInputArrow,
  ScrollDownIcon,
  SearchIcon,
  CloseTagIcon,
  CrossIcon,
  BurgerMenuIcon,
  InstagramIcon,
  FacebookIcon,
  TwitterIcon,
  DiscordIcon,
  BuyMeACoffeeIcon,
  BuyMeACoffeeRoundIcon,
  TelegramIcon,
};
