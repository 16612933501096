import { CacheProvider, EmotionCache, Global } from "@emotion/react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import type { AppProps } from "next/app";
import React from "react";
import Head from "next/head";

import AppContent from "../src/components/AppContent";
import createEmotionCache from "../src/utils/createEmotionCache";
import theme from "../src/constants/theme";

import { globalStyles } from "../styles/styles";
import "../styles/styles.css";
import { MainWrapper } from "../src/components/common/styles";

type Props = AppProps & {
  emotionCache?: EmotionCache;
};

const MyApp: React.FC<Props> = ({ Component, pageProps, emotionCache }) => {
  const AppComponent = Component as React.FC;

  return (
    <>
      <Head>
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1'
        ></meta>
        <meta name='description'></meta>
        <meta
          name='keywords'
          content='free sudoku, puzzles sudoku, game of sudoku, play sudoku online'
        />
        <title>OmiPlay</title>
      </Head>
      <CssBaseline>
        <CacheProvider value={emotionCache ?? createEmotionCache()}>
          <Global styles={globalStyles} />
          <ThemeProvider theme={theme}>
            <MainWrapper>
              <AppContent>
                <AppComponent {...pageProps} />
              </AppContent>
            </MainWrapper>
          </ThemeProvider>
        </CacheProvider>
      </CssBaseline>
    </>
  );
};

export default MyApp;
