import * as Props from "../types/PropsTypes";

const OmiPlayIcon: React.FC<Props.Icon> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="28"
      viewBox="0 0 189 28"
      fill="none"
      className={className}
    >
      <path
        d="M14.9873 28C12.1362 28 9.56771 27.3974 7.28173 26.1923C5.02144 24.9872 3.23633 23.3205 1.92638 21.1923C0.642128 19.0641 0 16.6667 0 14C0 11.3333 0.642128 8.9359 1.92638 6.80769C3.23633 4.67949 5.02144 3.01282 7.28173 1.80769C9.56771 0.602564 12.1362 0 14.9873 0C17.8383 0 20.394 0.602564 22.6543 1.80769C24.9403 3.01282 26.7254 4.67949 28.0096 6.80769C29.3196 8.9359 29.9745 11.3333 29.9745 14C29.9745 16.6667 29.3196 19.0641 28.0096 21.1923C26.7254 23.3205 24.9403 24.9872 22.6543 26.1923C20.394 27.3974 17.8383 28 14.9873 28ZM14.9873 21.6923C16.3486 21.6923 17.5815 21.3718 18.6859 20.7308C19.7904 20.0897 20.6637 19.1923 21.3058 18.0385C21.9479 16.859 22.269 15.5128 22.269 14C22.269 12.4872 21.9479 11.1538 21.3058 10C20.6637 8.82051 19.7904 7.91026 18.6859 7.26923C17.5815 6.62821 16.3486 6.30769 14.9873 6.30769C13.626 6.30769 12.3931 6.62821 11.2886 7.26923C10.1842 7.91026 9.31086 8.82051 8.66873 10C8.0266 11.1538 7.70554 12.4872 7.70554 14C7.70554 15.5128 8.0266 16.859 8.66873 18.0385C9.31086 19.1923 10.1842 20.0897 11.2886 20.7308C12.3931 21.3718 13.626 21.6923 14.9873 21.6923Z"
        fill="#000000"
      />
      <path
        d="M58.3195 27.4615L58.2424 13.1923L51.3074 24.8077H47.917L41.0206 13.5769V27.4615H33.97V0.538461H40.25L49.7278 16.0769L58.9745 0.538461H65.2545L65.3315 27.4615H58.3195Z"
        fill="#000000"
      />
      <path
        d="M70.7293 0.538461H78.3578V27.4615H70.7293V0.538461Z"
        fill="#000000"
      />
      <path
        d="M96.114 0.538461C98.554 0.538461 100.673 0.948718 102.471 1.76923C104.269 2.5641 105.656 3.71795 106.632 5.23077C107.608 6.71795 108.096 8.47436 108.096 10.5C108.096 12.5256 107.608 14.2821 106.632 15.7692C105.656 17.2564 104.269 18.4103 102.471 19.2308C100.673 20.0256 98.554 20.4231 96.114 20.4231H91.4136V27.4615H83.7851V0.538461H96.114ZM95.6516 14.4231C97.2184 14.4231 98.3999 14.0897 99.1962 13.4231C99.9924 12.7308 100.391 11.7564 100.391 10.5C100.391 9.24359 99.9924 8.26923 99.1962 7.57692C98.3999 6.88462 97.2184 6.53846 95.6516 6.53846H91.4136V14.4231H95.6516Z"
        fill="#5E7EF0"
      />
      <path
        d="M111.815 0.538461H119.444V21.4231H132.312V27.4615H111.815V0.538461Z"
        fill="#5E7EF0"
      />
      <path
        d="M153.701 22.2308H142.297L140.178 27.4615H132.396L144.301 0.538461H151.814L163.757 27.4615H155.82L153.701 22.2308ZM151.467 16.6154L147.999 8L144.532 16.6154H151.467Z"
        fill="#5E7EF0"
      />
      <path
        d="M178.675 17.8077V27.4615H171.046V17.6923L160.759 0.538461H168.811L175.207 11.2308L181.603 0.538461H189L178.675 17.8077Z"
        fill="#5E7EF0"
      />
    </svg>
  );
};

export default OmiPlayIcon;
