import { BurgerMenuIcon, CrossIcon } from "../../svg";
import * as Props from "../../types/PropsTypes";
import { Button } from "../common/Common.components";
import styles from "./Header.module.scss";

export const Header: React.FC<Props.Base> = ({ children }) => (
  <header className={styles.header}>{children}</header>
);

export const Wrapper: React.FC<Props.Base> = ({ children }) => (
  <div className={styles.wrapper}>{children}</div>
);

export const WrapperLinks: React.FC<Props.Base> = ({ children }) => (
  <div className={styles.wrapperLinks}>{children}</div>
);

export const BurgerButton: React.FC<Props.MenuButton> = ({
  onClick,
  isMenuOpen,
}) => (
  <Button
    onClick={onClick}
    ariaLabel="Open/Close Mobile Menu"
    ariaExpanded={isMenuOpen ? true : false}
    className={styles.menuButton}
  >
    {isMenuOpen ? (
      <CrossIcon className={styles.menuButtonIconCross} />
    ) : (
      <BurgerMenuIcon className={styles.menuButtonIconBurger} />
    )}
  </Button>
);
