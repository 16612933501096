import React, { useEffect } from "react";
import * as Props from "../../../types/PropsTypes";
import {
  Socials,
  SudokuProLink,
  MahjongLink,
  BuyUsACoffee,
} from "../../common/Common.components";
import { MobileMenuWrapper } from "./MobileMenu.components";

const MobileMenu: React.FC<Props.MobileMenu> = ({ isMenuOpen }) => {
  useEffect(() => {
    if (isMenuOpen) document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isMenuOpen]);

  return (
    <MobileMenuWrapper isMenuOpen={isMenuOpen}>
      <BuyUsACoffee section="header" />
      <SudokuProLink section="header" />
      <MahjongLink section="header" />

      <Socials section="header" />
    </MobileMenuWrapper>
  );
};

export default MobileMenu;
