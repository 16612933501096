import React from "react";
import COLORS from "../constants/colors";

const ClockIcon: React.FC = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.3347 0.000244141H5.66573C2.26816 0.000244141 0.000732422 2.43284 0.000732422 5.91624V14.0842C0.000732422 17.5713 2.26143 20.0002 5.66573 20.0002H14.3337C17.7383 20.0002 20.0007 17.5709 20.0007 14.0842V5.91624C20.0007 2.42975 17.7385 0.000244141 14.3347 0.000244141ZM5.66573 1.50024H14.3347C16.8852 1.50024 18.5007 3.23521 18.5007 5.91624V14.0842C18.5007 16.7654 16.8851 18.5002 14.3337 18.5002H5.66573C3.11487 18.5002 1.50073 16.766 1.50073 14.0842V5.91624C1.50073 3.23862 3.12107 1.50024 5.66573 1.50024ZM10.7435 5.53197C10.6938 5.1659 10.38 4.88374 10.0003 4.88374C9.58612 4.88374 9.25033 5.21953 9.25033 5.63374V9.99474L9.25869 10.1065C9.29182 10.3265 9.42166 10.5228 9.61608 10.6388L13.0071 12.6618L13.098 12.7081C13.4378 12.853 13.8409 12.7281 14.0354 12.402L14.0817 12.3111C14.2266 11.9713 14.1017 11.5682 13.7756 11.3737L10.75 9.56804L10.7503 5.63374L10.7435 5.53197Z'
        fill={COLORS.MAIN}
      />
    </svg>
  );
};

export default ClockIcon;
