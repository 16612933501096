import React, { useEffect, useState } from "react";
import {
  MOBILE_MEDIA_QUERY,
  TABLET_MEDIA_QUERY,
} from "../../constants/breakpoints";
import Container from "../Container";
import MobileMenu from "./MobileMenu";

import {
  BuyUsACoffee,
  Logo,
  MahjongLink,
  SudokuProLink,
} from "../common/Common.components";
import {
  BurgerButton,
  Header,
  Wrapper,
  WrapperLinks,
} from "./Header.components";

import useMediaQuery from "../../utils/useMediaQuery";

const HeaderContent: React.FC = () => {
  const isMobile = useMediaQuery(MOBILE_MEDIA_QUERY);
  const isTablet = useMediaQuery(TABLET_MEDIA_QUERY);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    if (isTablet) setMenuOpen(false);
  }, [isTablet]);

  return (
    <>
      <Header>
        <Container>
          <Wrapper>
            <Logo />
            {isMobile && (
              <BurgerButton
                onClick={() => setMenuOpen((prev) => !prev)}
                isMenuOpen={menuOpen}
              />
            )}
            {isTablet && (
              <WrapperLinks>
                <SudokuProLink section="header" />
                <MahjongLink section="header" />
                <BuyUsACoffee section="header" />
              </WrapperLinks>
            )}
          </Wrapper>
        </Container>
      </Header>

      <MobileMenu isMenuOpen={menuOpen} />
    </>
  );
};

export default HeaderContent;
