import { BuyMeACoffeeIcon } from "../../svg";
import * as Props from "../../types/PropsTypes";
import { StyledLink } from "../common/Common.components";
import styles from "./Footer.module.scss";

export const Footer: React.FC<Props.Base> = ({ children }) => (
  <footer className={styles.footer}>{children}</footer>
);

export const Wrapper: React.FC<Props.Base> = ({ children }) => (
  <div className={styles.wrapper}>{children}</div>
);

export const WrapperLinks: React.FC<Props.Base> = ({ children }) => (
  <div className={styles.wrapperLinks}>{children}</div>
);

export const PrivacyPolicyLink: React.FC = () => (
  <StyledLink href="/privacy-policy" rel target="_blank">
    Privacy Policy
  </StyledLink>
);

export const Copyright: React.FC = () => (
  <p className={styles.сopyright}>
    {`Copyright © ${new Date().getFullYear()} `}
    <StyledLink
      href="https://omisoft.net?utm_source=omiplay.com&utm_medium=referral"
      target="_blank"
      rel
    >
      OmiSoft LLC
    </StyledLink>
    . All Rights Reserved
  </p>
);

export const BuyUsACoffee: React.FC = () => (
  <StyledLink
    ariaLabel="Buy Me A Coffee OmiPlay Link"
    href="https://buymeacoffee.com/omiplay"
    rel
    target="_blank"
  >
    <div className={styles.buyMeACoffeeButton}>
      <BuyMeACoffeeIcon className={styles.buyMeACoffeeIcon} />

      <p>Buy us a Coffee</p>
    </div>
  </StyledLink>
);
