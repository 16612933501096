import React from "react";
import COLORS from "../constants/colors";

const SearchIcon: React.FC = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='26'
      height='26'
      viewBox='0 0 26 26'
      fill='none'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.5144 0.5C5.87904 0.5 0.5 5.76024 0.5 12.2491C0.5 18.7379 5.87904 23.9982 12.5144 23.9982C15.3524 23.9982 17.9605 23.0359 20.0162 21.4269L23.9213 25.2358L24.0252 25.3233C24.3878 25.5857 24.9018 25.5558 25.2298 25.2341C25.5907 24.8803 25.59 24.3074 25.2281 23.9545L21.369 20.1904C23.3312 18.0992 24.5289 15.3109 24.5289 12.2491C24.5289 5.76024 19.1498 0.5 12.5144 0.5ZM12.5144 2.30967C18.1278 2.30967 22.6783 6.7597 22.6783 12.2491C22.6783 17.7385 18.1278 22.1885 12.5144 22.1885C6.90107 22.1885 2.35054 17.7385 2.35054 12.2491C2.35054 6.7597 6.90107 2.30967 12.5144 2.30967Z'
        fill={COLORS.MAIN}
      />
    </svg>
  );
};

export default SearchIcon;
